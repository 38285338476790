/* You can add global styles to this file, and also import other style files */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer components {
  .auth-btn{
    @apply group text-2xl relative w-full flex justify-center py-2 px-4 border
     rounded-md text-white bg-indigo-600 hover:bg-indigo-700
    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50;
  }
  .a-normal{
    @apply font-medium text-indigo-600 hover:text-indigo-900;
  }
  .a-list{
    @apply cursor-pointer   hover:text-indigo-900;
  }
  .a-list-2{
    @apply cursor-pointer pt-5 text-2xl  hover:text-indigo-900;
  }

  .a-list-adress{
    @apply cursor-pointer text-blue-600  hover:text-indigo-900;
  }

  .a-generate{
    @apply  text-red-700  md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-2xl;
  }
  .a-title{
    @apply cursor-pointer font-bold  hover:text-indigo-900 italic;
  }

  .btn-blue {
    @apply py-2 px-4 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;
  }


.btn-normal{
  @apply  bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full;
}
.btn-elevated{
  @apply  bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow text-sm;
}

.cart-title-total{
  @apply text-center text-atributo-Verde text-2xl;
}

.form-address{
  @apply  flex items-center  w-full bg-gray-300;
}
.form-address-2{
  @apply  w-full bg-white rounded shadow-2xl p-8 m-4 sm:max-w-sm max-w-lg   mx-auto;
}
.form-auth{
  @apply  flex items-center h-screen w-full bg-gray-300;
}
.form-auth-2{
  @apply w-full bg-white rounded shadow-lg p-8 m-4 max-w-sm mx-auto;
}
.form-auth-3{
  @apply mt-5 space-y-6;
}

.form-field{
  @apply appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm;
}
.form-field-code{
  @apply appearance-none rounded-none relative block w-full  px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-2xl w-24;
}
.label-title-cart{
  @apply text-2xl text-left text-red-700;
}


.label-title-cart-sm{
  @apply text-lg text-left text-red-700;
}
.label-title-list{
  @apply text-xl text-left text-green-700;
}
.label-title{
  @apply text-2xl text-left text-red-700;
}
.label-title-sm{
  @apply text-lg text-left text-blue-700;
}
.label-title-2{
  @apply text-lg text-left text-blue-700;
}
.txt-brand {
  @apply text-left text-blue-700;
}
.txt-class{
  @apply text-left text-atributo-Negro text-sm sm:text-base md:text-sm sm:pl-2;
}

.txt-generate{
  @apply text-xs text-blue-500  md:text-lg lg:text-lg xl:text-lg 2xl:text-lg;
}

.txt-info{
  @apply text-center text-atributo-Azul text-xl;
}
.txt-name  {
  @apply text-center text-gray-700 bg-gray-100;
}
.txt-name-sm  {
  @apply  text-base pr-2;
}

.txt-price {
  @apply text-center text-atributo-Rojo text-lg;
}

.txt-title-generate{
  @apply mt-6 text-center sm:text-xl text-3xl font-extrabold text-gray-900
}
.txt-title-auth{
  @apply mt-6 text-center text-xl font-extrabold text-gray-900
}
.txt-title-auth-2{
  @apply mt-6 text-center text-xl font-extrabold text-gray-900
}

.txt-label-3{
  @apply text-left text-red-700 text-base;
}
.txt-title-list{
@apply text-left text-red-700 text-base;
}
.txt-title-class{
  @apply text-left text-atributo-Celeste text-lg;
}

.txt-title-group{
@apply text-center text-atributo-Rojo text-lg;
}
.txt-alert-danger{
@apply text-right text-atributo-Rojo text-base;
}
.view-sm{
  @apply md:hidden lg:hidden xl:hidden 2xl:hidden;
}
}
